import React, { useEffect, useRef, useState } from "react";
import { BsCheck } from 'react-icons/bs/'
import { doc, getDoc, collection } from "firebase/firestore";
import { db } from "../../firebase"

export default function FirstForm({ formData, setFormData, lang }: { formData: any, setFormData: any, lang: string }) {

    const selectRef = useRef<HTMLDivElement>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    // Form lists
    const [extraServicesOptions, setExtraServicesOptions] = useState<any>(null);
    const [parkingOptions, setParkingOptions] = useState<any>(null);
    const [deliveryOptions, setDeliveryOptions] = useState<any>(null);
    const [urgency, setUrgency] = useState<boolean>(false);
    const [baseValue, setBaseValue] = useState('');
    // const cityOptions = ['faro'];

    // Fetch form lists
    useEffect(() => {
        const fetchData = async () => {
            const city = collection(db, formData.city);
            let docRef = doc(city, 'redpark');
            let docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setParkingOptions(docSnap.data().parkingType);
                setExtraServicesOptions(docSnap.data().extraServices);
                setBaseValue(docSnap.data().baseValue);
            }
            docRef = doc(city, 'general');
            docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setDeliveryOptions(docSnap.data().delivery);
            }
        };
        if (formData.city) {
            setParkingOptions(null);
            setDeliveryOptions(null);
            setExtraServicesOptions(null);
            setBaseValue('');
            fetchData();
        }
    }, [formData.city]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selectRef]);

    // Calculate price
    useEffect(() => {
        const calculateDays = (start: string, end: string): number => {
            if (!start || !end) return 0;
            const startDate = new Date(start);
            const endDate = new Date(end);
            const timeDiff = endDate.getTime() - startDate.getTime() + 1;
            const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
            return days === 1 ? 2 : days;
        };

        let result = 0;

        // Base Value
        if (formData.delivery)
            result = parseInt(baseValue);
        // Delivery
        if (formData.delivery)
            result = result + formData.delivery.price;
        // Parking
        if (formData.parking) {
            const days = calculateDays(formData.checkinDate, formData.checkoutDate);
            const parkingCost = days * formData.parking.price;
            result = result + parkingCost;
        }
        // Extra Services
        if (formData.extraServices) {
            (formData.extraServices).forEach((service: any) => {
                result = result + service.price;
            })
        }
        
        if (urgency) {
            result = result + 10;
        }

        // Save price
        if (!isNaN(result)) {
            setFormData((previousFormData: any) => ({
                ...previousFormData,
                bookingPrice: result.toString(),
            }));
        } else {
            setFormData((previousFormData: any) => ({
                ...previousFormData,
                bookingPrice: "Error calculating price",
            }));
        }
    }, [formData.checkinDate, formData.delivery, formData.checkoutDate, formData.extraServices, formData.parking, lang, extraServicesOptions, baseValue, setFormData, urgency]);

    useEffect(() => {
        const checkUrgency = () => {
            const checkinDate = new Date(formData.checkinDate);
            const checkinTime = new Date(checkinDate.getFullYear(), checkinDate.getMonth(), checkinDate.getDate(), parseInt(formData.inputTimeHours), parseInt(formData.inputTimeMinutes));
            const now = new Date();
            const diff = checkinTime.getTime() - now.getTime();
            if (diff < 7200000) {
                setUrgency(true);
            } else {
                setUrgency(false);
            }
        }
        checkUrgency();
    }, [formData.checkinDate, formData.inputTimeHours, formData.inputTimeMinutes]);

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleSelectOption = (ele: any) => {
        setFormData((prevFormData: any) => {
            const currentExtraServices = prevFormData.extraServices || [];
            const newExtraServices = currentExtraServices.some((service: any) => service['pt'] === ele['pt'])
                ? currentExtraServices.filter((service: any) => service['pt'] !== ele['pt'])
                : [...currentExtraServices, ele];
            return {
                ...prevFormData,
                extraServices: newExtraServices
            };
        });
    };

    const handleChange = (e: any) => {
        if (e.target.name === "licensePlate") {
            setFormData({ ...formData, [e.target.name]: (e.target.value.replace(/-/g, '')).toUpperCase() });
        } else if (e.target.name === 'parking') {
            const selectedParking = parkingOptions.find((option: any) => option['pt'] === e.target.value);
            setFormData({ ...formData, [e.target.name]: selectedParking });
        } else if (e.target.name === 'delivery') {
            const selectedDelivery = deliveryOptions.find((option: any) => option['pt'] === e.target.value);
            setFormData({ ...formData, [e.target.name]: selectedDelivery });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    return (
        <>
            {/* <div className="flex items-center gap-4">
                <label htmlFor="parking" className="w-1/3">
                    {lang === "pt" ? "Cidade:" : "City:"}
                </label>
                <select
                    name="city"
                    id="city"
                    value={formData.city}
                    className="w-2/3 form-select bg-[transparent] px-6 py-2 h-10 border-2 rounded-full border-black"
                    onChange={handleChange}
                >
                    <option value="">{lang === 'en' ? 'Select an option' : 'Selecione uma opção'}</option>
                    {cityOptions && cityOptions?.map((option: any, index: number) => (
                        <option key={index} value={option} className="text-black capitalize bg-white">
                            {option}
                        </option>
                    ))}
                </select>
            </div> */}
            {
                formData.city &&
                <>
                    <div className="flex items-center gap-4">
                        <label htmlFor="parking" className="w-1/3">
                            Parking:
                        </label>
                        <select
                            name="parking"
                            id="parking"
                            value={formData.parking ? formData.parking['pt'] : ''}
                            className="w-2/3 form-select bg-[transparent] px-6 py-2 h-10 border-2 rounded-full border-black"
                            onChange={handleChange}
                        >
                            <option value="">{lang === 'en' ? 'Select an option' : 'Selecione uma opção'}</option>
                            {parkingOptions && parkingOptions?.map((ele: any, index: number) => (
                                <option key={index} value={ele['pt']} className="text-black bg-white">
                                    {`${ele[lang]} (${ele?.price}€)`}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* Delivery Options */}
                    <div className="flex items-center gap-4">
                        <label htmlFor="delivery" className="w-1/3">
                            {lang === "pt" ? "Entrega em:" : "Delivery at:"}
                        </label>
                        <select
                            name="delivery"
                            id="delivery"
                            value={formData.delivery ? formData.delivery['pt'] : ''}
                            className="w-2/3 form-select bg-[transparent] px-6 py-2 h-10 border-2 rounded-full border-black "
                            onChange={handleChange}
                        >
                            <option value="">{lang === 'en' ? 'Select an option' : 'Selecione uma opção'}</option>
                            {deliveryOptions && deliveryOptions?.map((ele: any, index: number) => (
                                <option key={index} value={ele['pt']} className="text-black bg-white">
                                    {`${ele[lang]} (${ele?.price}€)`}
                                </option>
                            ))}
                        </select>

                    </div>
                    {/* Extra Services */}
                    <div className="flex items-center gap-4">
                        <label htmlFor="extraServices" className="w-1/3">
                            {lang === "pt" ? "Serviços extra:" : "Extra services:"}
                        </label>
                        <div
                            className="relative w-2/3 h-10 px-6 py-2 border-2 border-black rounded-full cursor-pointer"
                            onClick={handleToggleDropdown}
                            ref={selectRef}
                        >
                            {formData.extraServices.length} {lang === 'en' ? 'selected' : `selecionado${formData.extraServices.length === 1 ? '' : 's'}`}
                            {isDropdownOpen && (
                                <div className="absolute w-full left-0 top-10 bg-white border-2 border-black rounded-md z-10 h-[15rem] overflow-y-scroll">
                                    {extraServicesOptions && extraServicesOptions.map((ele: any, index: number) => (
                                        <div
                                            key={index}
                                            className="flex items-center gap-2 p-2 pl-4 hover:bg-gray-100"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleSelectOption(ele);
                                            }}>
                                            <div className={`w-4 h-4 border-[1px] border-black rounded-[4px] ${formData.extraServices.some((service: any) => service['pt'] === ele['pt']) ? 'bg-[var(--primary)]' : ''}`}>
                                                <span className={`${formData.extraServices.some((service: any) => service['pt'] === ele['pt']) ? 'flex' : 'hidden'} text-white`}><BsCheck /></span>
                                            </div>
                                            <p className="ml-2 text-sm">{`${ele[lang]} (${ele?.price}€)`}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            }
            {/* Return Flight Date */}
            <div className="flex items-center gap-4">
                <label htmlFor="returnFlight" className="w-1/3">
                    {lang === "pt" ? "Voo/cidade de regresso:" : "Return Flight/City:"}
                </label>
                <input
                    type="text"
                    id="returnFlight"
                    name="returnFlight"
                    placeholder="|"
                    value={formData.returnFlight}
                    className="w-2/3 form-input bg-[transparent] px-6 py-[5px] sete:py-2 border-2 rounded-full border-black"
                    onChange={handleChange}
                />
            </div>
            {/* Check-in Date */}
            <div className="flex items-center gap-4">
                <label htmlFor="checkinDate" className="w-1/3">
                    {lang === "pt" ? "Data de entrada:" : "Check-in Date:"}
                </label>
                <input
                    type="date"
                    id="checkinDate"
                    value={formData.checkinDate}
                    name="checkinDate"
                    className="w-2/3 form-input bg-[transparent] px-6 py-[5px] sete:py-2 border-2 rounded-full border-black"
                    onChange={handleChange}
                />
            </div>
            {/* Input Time */}
            <div className="flex items-center gap-4">
                <label htmlFor="inputTimeHours" className="w-1/3">
                    {lang === "pt" ? "Hora de entrada:" : "Check-in Time:"}
                </label>
                <div className="flex w-2/3 gap-4">
                    <select
                        id="inputTimeHours"
                        name="inputTimeHours"
                        value={formData.inputTimeHours}
                        className="w-1/2 form-select bg-[transparent] px-6 py-[5px] sete:py-2 rounded-full border-black border-2"
                        onChange={handleChange}
                    >
                        <option value="">{lang === "pt" ? "Hora" : "Hour"}</option>
                        {Array.from({ length: 24 }, (_, hour) => (
                            <option key={hour} value={hour}>
                                {hour.toString().padStart(2, '0')}
                            </option>
                        ))}
                    </select>
                    <select
                        id="inputTimeMinutes"
                        name="inputTimeMinutes"
                        value={formData.inputTimeMinutes}
                        className="w-1/2 form-select bg-[transparent] px-6 py-[5px] sete:py-2 rounded-full border-black border-2"
                        onChange={handleChange}
                    >
                        <option value="">{lang === "pt" ? "Minuto" : "Minute"}</option>
                        {
                            Array.from({ length: 12 }, (_, index) => {
                                const minute = index * 5; // every 5 minutes
                                return (
                                    <option key={index} value={minute.toString().padStart(2, '0')}>
                                        {minute.toString().padStart(2, '0')}
                                    </option>
                                );
                            })
                        }
                    </select>
                </div>
            </div>
            {/* Departure Date */}
            <div className="flex items-center gap-4">
                <label htmlFor="checkoutDate" className="w-1/3">
                    {lang === "pt" ? "Data de saída:" : "Departure Date:"}
                </label>
                <input
                    type="date"
                    id="checkoutDate"
                    name="checkoutDate"
                    value={formData.checkoutDate}
                    className="w-2/3 form-input bg-[transparent] px-6 py-[5px] sete:py-2 border-2 rounded-full border-black"
                    onChange={handleChange}
                />
            </div>
            {/* Output Time */}
            <div className="flex items-center gap-4">
                <label htmlFor="outputTimeHours" className="w-1/3">
                    {lang === "pt" ? "Hora de saída:" : "Departure Time:"}
                </label>
                <div className="flex w-2/3 gap-4">
                    <select
                        id="outputTimeHours"
                        name="outputTimeHours"
                        value={formData.outputTimeHours}
                        className="w-1/2 form-select bg-[transparent] px-6 py-[5px] sete:py-2 rounded-full border-black border-2"
                        onChange={handleChange}
                    >
                        <option value="">{lang === "pt" ? "Hora" : "Hour"}</option>
                        {Array.from({ length: 24 }, (_, hour) => (
                            <option key={hour} value={hour}>
                                {hour.toString().padStart(2, '0')}
                            </option>
                        ))}
                    </select>
                    <select
                        id="outputTimeMinutes"
                        name="outputTimeMinutes"
                        value={formData.outputTimeMinutes}
                        className="w-1/2 form-select bg-[transparent] px-6 py-[5px] sete:py-2 rounded-full border-black border-2"
                        onChange={handleChange}
                    >
                        <option value="">{lang === "pt" ? "Minuto" : "Minute"}</option>
                        {
                            Array.from({ length: 12 }, (_, index) => {
                                const minute = index * 5; // every 5 minutes
                                return (
                                    <option key={index} value={minute.toString().padStart(2, '0')}>
                                        {minute.toString().padStart(2, '0')}
                                    </option>
                                );
                            })
                        }
                    </select>
                </div>
            </div>
            {/* License Plate */}
            <div className="flex items-center gap-4">
                <label htmlFor="licensePlate" className="w-1/3">
                    {lang === "pt" ? "Matrícula:" : "License Plate:"}
                </label>
                <input
                    type="text"
                    id="licensePlate"
                    placeholder="|"
                    name="licensePlate"
                    value={formData.licensePlate}
                    className="uppercase w-2/3 form-input bg-[transparent] px-6 py-[5px] sete:py-2 border-2 rounded-full border-black"
                    onChange={handleChange}
                />
            </div>
            {/* Remarks */}
            <div className="flex items-center gap-4">
                <label htmlFor="remarks" className="w-1/3">
                    {lang === "pt" ? "Observações:" : "Remarks:"}
                </label>
                <input
                    type="text"
                    id="remarks"
                    placeholder="|"
                    name="remarks"
                    value={formData.remarks}
                    className="w-2/3 form-input bg-[transparent] px-6 py-[5px] sete:py-2 border-2 rounded-full border-black"
                    onChange={handleChange}
                />
            </div>
            {/* Booking Price */}
            <div className="flex items-center gap-4">
                <label htmlFor="bookingPrice" className="w-1/3">
                    {lang === "pt" ? "Preço da reserva:" : "Booking Price:"}
                </label>
                <input
                    type="text"
                    id="bookingPrice"
                    placeholder="| 10"
                    name="bookingPrice"
                    value={formData.bookingPrice + '€'}
                    disabled
                    className="w-2/3 form-input bg-[#DDD] px-6 py-[5px] sete:py-2 border-2 rounded-full border-black"
                />
            </div>
        </>
    );
}

