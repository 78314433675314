import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../content/images/redpark.png";
import LogoMultipark from "../../../content/images/multipark.png";
export default function Navbar() {
  const [lang, setLang] = useState("pt");
  const [active, setActive] = useState(false);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setLang(lang);
    }
  }, []);

  return (
    <div className={`absolute w-full top-0 left-0 h-[4rem] z-[10]`}>
      <div className="w-full h-full px-[5vw] flex justify-between items-center gap-4 z-[2] bg-white">
        <div className="flex items-center gap-4">
          <Link
            className={`relative font-bold hover:text-[var(--primary)] transition-all duration-300 text-[1.2rem]`}
            to="/"
          >
            <img src={Logo} alt="logo" className="w-[7rem]" />
          </Link>
          <div>
            <p className="pl-2 text-[.9rem]">
              {lang === "pt" ? "Grupo" : "Group"}
            </p>
            <Link
              className={`relative font-bold hover:text-[var(--primary)] transition-all duration-300 text-[1.2rem]`}
              to="/"
            >
              <img src={LogoMultipark} alt="logo" className="w-[7rem]" />
            </Link>
          </div>
        </div>
        <div className={`gap-8 hidden sete:flex`}>
          <NavbarLinks lang={lang} />
        </div>
        <div
          onClick={() => setActive(!active)}
          className="sete:hidden flex flex-col gap-[4px]"
        >
          <div
            className={`w-6 h-[3px] bg-black ${
              active && "rotate rotate-45 translate-y-[.35rem]"
            } transition-all duration-300`}
          ></div>
          <div className={`w-6 h-[3px] bg-black ${active && "hidden"}`}></div>
          <div
            className={`w-6 h-[3px] bg-black ${
              active && "rotate -rotate-45 translate-y-[-.10rem]"
            } transition-all duration-300`}
          ></div>
        </div>
      </div>
      {active && (
        <div
          id="mobile-navbar"
          className="flex flex-col gap-8 bg-white px-[5vw] items-end py-4 shadow-lg translate-y-[-125%] z-[1]"
        >
          <NavbarLinks lang={lang} />
        </div>
      )}
    </div>
  );
}

function NavbarLinks({ lang }: { lang: string }) {
  function isActive(href: string) {
    if (href === window.location.pathname) return "text-[var(--primary)]";
    return "";
  }

  return (
    <>
      <Link
        className={`${isActive(
          "/"
        )} font-bold hover:text-[var(--primary)] transition-all duration-300`}
        to="/"
      >
        {lang === "pt" ? "Início" : "Home"}
      </Link>
      <Link
        className={`${isActive(
          "/book"
        )} font-bold hover:text-[var(--primary)] transition-all duration-300`}
        to="/book"
      >
        {lang === "pt" ? "Reservar" : "Book"}
      </Link>
      <Link
        className={`${isActive(
          "/about"
        )} font-bold hover:text-[var(--primary)] transition-all duration-300`}
        to="/about"
      >
        {lang === "pt" ? "Sobre" : "About"}
      </Link>
      <Link
        className={`${isActive(
          "/services"
        )} font-bold hover:text-[var(--primary)] transition-all duration-300`}
        to="/services"
      >
        {lang === "pt" ? "Serviços" : "Services"}
      </Link>
      {/* <Link className={`${isActive("/blog")} font-bold hover:text-[var(--primary)] transition-all duration-300`} to="/blog">Blog</Link> */}
      <Link
        className={`${isActive(
          "/contact"
        )} font-bold hover:text-[var(--primary)] transition-all duration-300`}
        to="/contact"
      >
        {lang === "pt" ? "Contactar" : "Contact"}
      </Link>
      <Link
        className={`${isActive(
          "/book"
        )} font-bold hover:text-[var(--primary)] transition-all duration-300`}
        to={"https://multipark.pt"}
        target="_blank"
      >
        {lang === "pt" ? "Grupo" : "Group"}
      </Link>
    </>
  );
}
